.profile-page .profile-top {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
}
.profile-page .profile-top .left {
    flex: 0 2;
}
.profile-page .profile-top .right {
    flex: 1;
}
.profile-page .profile-top .right .mail {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: #FFFFFF;
}
.profile-page .editor .text {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.1px;
}