.UserMarketingJob .pall {
    background-color: #35384B;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
    color: #FFFFFF;
}

.UserMarketingJob .stext {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: #FFFFFF;
}

.UserMarketingJob .btn-wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.UserMarketingJob .btn-start {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: #000000;
}

.UserMarketingJob .card-body {
    background-color: #24192cde;
    color:#FFFFFF;
}
.UserMarketingJob .card {
    border: none;
}
.UserMarketingJob .admin-msg {
     display: flex;
     flex-direction: row;
     flex-wrap: nowrap;
     align-content: center;
     justify-content: flex-start;
}
.UserMarketingJob .msg {
     display: flex;
     flex-direction: row;
     flex-wrap: nowrap;
     align-content: center;
     justify-content: flex-end;
}
