:root {
    --back: #24192C;
}

#root {
    background-color: var(--back);
    height: 100vh;
    overflow: auto;
}

#root, html, body {
    width: 100%;
    height: 100%;
    font-family: 'IBM Plex Sans';
}
.vh{
    height: 100vh;
    width: 100vw;
}
body {
    margin: 0;
    color: #FFFFFF;
}