.UserMarketingMini {
    border: 1px solid #ffffff;
    border-radius: 11px;
}
.UserMarketingMini .str {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
}
.UserMarketingMini .top button.btn.btn-warning.btn-sm {
    border-radius: 1rem;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 20px;
    letter-spacing: 0.1px;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
}

.UserMarketingMini .top button.btn.btn-primary.btn-sm {
    border-radius: 1rem;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 20px;
    letter-spacing: 0.1px;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
}