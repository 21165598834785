.StartBody {
    font-size: 2.7rem;
    position: initial;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    line-height: 52px;
    text-align: center;
    color: #FFFFFF;
    z-index: 99;
}

.d-none {
    display: none !important;
}

.d-flex {
    display: flex !important;
}

.fadeIn {
    opacity: 0;
    animation: ani 1.5s forwards;
}

@keyframes ani {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.fadeOut {
    opacity: 1;
    animation: ani2 1.5s forwards;
}

@keyframes ani2 {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.fadeInBottom {
    transform: translateY(100%);
    animation: ani3 0.5s forwards;
}

@keyframes ani3 {
    0% {
        transform: translateY(200%);
    }
    100% {
        transform: translateY(0);
    }
}

.fadeOutBottom {
    transform: translateY(200%);
    animation: ani4 0.5s forwards;
}

@keyframes ani4 {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(200%);
    }
}

.fadeInTop {
    transform: translateY(-200%);
    animation: ani5 0.5s forwards;
}

@keyframes ani5 {
    0% {
        transform: translateY(-200%);
    }
    100% {
        transform: translateY(0);
    }
}

.fadeOutTop {
    transform: translateY(-150%);
    animation: ani6 0.5s forwards;
}

@keyframes ani6 {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(-200%);
    }
}

.fadeInLeft {
    transform: translateX(-200%);
    animation: ani7 0.8s forwards;
}

@keyframes ani7 {
    0% {
        transform: translateX(-200%);
    }
    100% {
        transform: translateX(0);
    }
}

.fadeOutLeft {
    transform: translateX(-150%);
    animation: ani8 0.8s forwards;
}

@keyframes ani8 {
    0% {
        transform: translateX(0);
    }
    100% {
        position: absolute;
        transform: translateX(-200%);
    }
}

.slideDown {
    height: 0;
    animation: ani9 0.8s forwards;overflow: auto;
}

@keyframes ani9 {
    0% {
        height: 0;
    }
    100% {
        height: 100%;
        height: auto;
    }
}

.slideUp {
    height: auto;
    animation: ani10 0.8s forwards;overflow: auto;
}

@keyframes ani10 {
    0% {
        height: auto;
    }
    100% {
        height: 0;
    }
}

.StartSelectLang {
    background-color: #35384B;
    height: 60%;
    position: absolute;
    width: calc(100% - calc(var(--bs-gutter-x) * 0.5) * 2);
    border-top-right-radius: 1rem;
    border-top-left-radius: 1rem;
    bottom: 0;
    border: 1px #BFBFBF solid;
    overflow: auto;
}

.StartSelectLang ul {
    list-style: none;
    padding: 0rem 0.2rem;
}

.StartSelectLang ul li {
    padding: 1rem 2rem 0.2rem 2rem;
    border-bottom: 1px #BFBFBF solid;
    font-weight: 500;
    font-size: 1.27rem;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
}

.StartSelectWrldImgWrap {
    width: 100%;
    position: absolute;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    top: 0;
    height: 50%;
}

.StartCountrySelect {
    position: absolute;
    height: 60%;
    bottom: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: flex-start;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    font-size: 1.5rem;
}

.d-flex-center {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: flex-start;
    justify-content: center;
    align-items: flex-start;
}

.pos-absolute {
    position: absolute;
}

.top-0 {
    top: 0;
}

.bottom-0 {
    bottom: 0;
}

.w-100 {
    width: 100%;
}

.container {
    height: auto;
    color: #FFFFFF;
    max-width: 100% !important;
}

/*
НОВЫЙ
*/

.bg-header {
    margin-right: calc(var(--bs-gutter-x) * -0.5) !important;
    margin-left: calc(var(--bs-gutter-x) * -0.5) !important;
    color: #FFFFFF;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.1px;
    background-color: #443453;
    padding: 0rem !important;
    z-index: 999999;
}

.bg-header-container {
    color: #FFFFFF;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.1px;
    background-color: #443453;
    padding: 1rem 0rem;
    display: flex;
    flex-direction: row !important;
    flex-wrap: nowrap !important;
    align-content: center !important;
    justify-content: center !important;
    align-items: center !important;
    min-height: calc(1rem + 24px);
}

/*
Custom Select styles
*/
.selectReact__control {
    background-color: #302538 !important;
    color: #fff;
    border: none !important;
}

.selectReact__control--is-focused {
    background-color: #302538 !important;
    color: #fff;
    border: none !important;
}

.selectReact__placeholder {
    color: #ffffff82 !important;
}

.selectReact__menu {
    background-color: #302538 !important;
}

selectReact__option--is-focused {
    background-color: #302538 !important;
}

.selectReact__single-value {
    color: #ffffff99 !important;
}

.selectReact__indicators {
    display: none !important;
}

.select-lg .selectReact__control {
    padding-top: 0.26rem;
    padding-bottom: 0.26rem;
    padding-left: 0.5rem;
    font-size: 1.24rem;
}

.select-lg > div > div > .selectReact__option--is-focused, .select-lg > div > div > .selectReact__option, .select-lg > div > div > .selectReact__option--is-focused {
    font-size: 1.24rem;
}

.selectReact__control {
    border-radius: 10px;
}

.selectReact__option--is-focused, .selectReact__option:active {
    background-color: #f0f8ff00 !important;
}

.selectReact__input-container .selectReact__input {
    color: #fff !important;
}

.selectReact__menu {
    color: #fff !important;
}

.text-muted {
    --bs-text-opacity: 1;
    color: #FFFFFF99 !important;
}

.socialAuth {
    display: inline-flex;
    width: 20%;
    height: auto;
    flex-direction: row;
    flex-wrap: nowrap;
    margin: 0;
    align-content: center;
    justify-content: center;
    align-items: center;
    padding-bottom: 1rem;
}

.form-control, .form-control:focus {
    background-color: #302538 !important;
    border: 1px solid #302538;
    border-radius: 8px;
    color: #fff;
}

.form-control::placeholder {
    color: rgba(102, 114, 127, 0.6);
}
.form-select,.form-select:focus {
    background-color: #302538 !important;
    border: 1px solid #302538;
    color: #FFFFFF;
}
label {
    color:#66727F;
}
.loading {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.form-check-input:checked {
    background-color: #EED033;
    border-color: #EED033;
}
input#custom-switch {
    width: 4em;
    height: 1.8em;
}