.alert_ios * {
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -ms-touch-action: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.alert_ios *:focus {
    outline: 0
}
.alert_back {
    width: 100%;
    height: 100%;
    top: 0;
    position: absolute;
    display: flex;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    background: #00000063;
    z-index: 9999999999999;
}
.alert_ios {
    text-align: center;
    font-family: -apple-system, SF UI Text, Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 14px;
    line-height: 1.4;
    border-radius: 13px;
    overflow: hidden;
    z-index: 9999999999999999999999998;
    background-color: rgba(255, 255, 255, 0.94);
    color: #000;
    width: 60%;
    max-width: 270px;
}

.alert_ios .inner {
    padding: 15px;
}

.alert_ios .inner .title {
    font-weight: 500;
    font-size: 18px;
}

.alert_ios .inner .text {
    margin-top: 5px;
}

.alert_ios .button {
    position: relative;
    height: 44px;
    line-height: 44px;
    font-size: 17px;
    color: #007aff;
    border-radius: 0 0 13px 13px;
    overflow: hidden;
    cursor: pointer;
}

.alert_ios .button:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 1px;
    width: 100%;
    display: block;
    background-color: #c4c4c4;
    z-index: 9999999999999999999999999;
}

.button.button_danger {
    color: darkred !important;
}
.button.button_success {
    color: lawngreen !important;
}
.alert_ios .button.btn_split_0 {
    width: 49%;
    display: inline-block;
    border-right: 1px solid #c4c4c4;
    border-radius: 0;
}
.alert_ios .button.btn_split_1 {
    width: 49%;
    display: inline-block;
}