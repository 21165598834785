.footer-nav {
    background-color: #443453 !important;
    width: 100%;
    position: absolute;
    bottom: 0;
    margin: 0;
    padding: 0;
    right: 0;
    left: 0;
    border-radius: 0;
    height: 4rem;
    z-index: 2;
}

.footer-nav button {
    background-color: #443453 !important;
    border-radius: 0;
    width: 20% !important;
}

.footer-nav button.active {
    background-color: #3A2B35 !important;
}

.footer-nav button.active div {
    width: 100%;
    height: 0.2rem;
    position: absolute;
    left: 0;
    bottom: 0.05rem;
    background: #EED033;
}