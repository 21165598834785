.UserCashbackOnlineMini {
    border: 1px solid #FFFFFF;
    border-radius: 11px;
    min-height: 6rem;
}

.UserCashbackOnlineMini .first, .UserCashbackOnlineMini .second {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 20px;
    color: #FFFFFF;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
}

.UserCashbackOnlineMini .second {
    color: #ABAEC5;
}

.UserCashbackOnlineMini .third {
    display: flex;
}

.UserCashbackOnlineMini .third .left {
    flex: 3 1;
}
.UserCashbackOnlineMini .third .right {
    flex: 2 1;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: flex-end;
}

.UserCashbackOnlineMini .third .left img {
    border-radius: 11px;
    width: 100%;
    max-width: 200px;
}


.UserCashbackOnlineMini .third .left.no-image {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
}
.UserCashbackOnlineMini .third .left.no-image span {
    color: #888888;
    font-size: 1rem;
}

.UserCashbackOnlineMini .third .right .tsuccess {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #7BC043;
}
.UserCashbackOnlineMini .third .right .tdeclined {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #ff0000;
}
.UserCashbackOnlineMini .third .right .tgetfast button {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 8px;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: #000000;
}

.UserCashbackOnlineMini .third .right .bottom {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    color: #FFFFFF;
}