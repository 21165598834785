
.GoodPage .title {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 1.1rem;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.1px;
}
.GoodPage .poster {
    max-width: 100%;
    padding-top: 1rem;
    min-height: 22rem;
    width: 100%;
}
.GoodPage .description {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 0.8rem;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: #FFFFFF;
    padding-top: 2rem;
}

.GoodPage .site_link .link {
    padding: 0.2rem;
    border: 1px solid #FFFFFF;
    border-radius: 5px;
    margin-top: 0.6rem;
}
.GoodPage .socialShare {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
}
.GoodPage .price {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 20px;
    letter-spacing: 0.1px;
    padding-top: 1rem;
}
.GoodPage .price_cash {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 0.8rem;
    line-height: 20px;
    letter-spacing: 0.1px;
    padding-top: 0.5rem;
}
.GoodPage .btns button, .GoodPage .btns a{
    width: 100%;
    font-size: 0.9rem;
}
.GoodPage .btns{
display: flex;
}
.page-goods .no-found {
    width: 100%;
    text-align: center;
    font-weight: bold;
}

.page-goods .btn-group.filter-group button.btn.btn-outline-light:first-child {
    border-top-left-radius: 11px;
    border-bottom-left-radius: 11px;
}
.page-goods .btn-group.filter-group button.btn.btn-outline-light:last-child {
    border-top-right-radius: 11px;
    border-bottom-right-radius: 11px;
}
.page-goods .btn-group.filter-group button.btn.btn-outline-light {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.1px;
    color: #FFFFFF;
}

.page-goods .btn-group.filter-group button.btn.btn-outline-light.btn:hover {
    color: var(--bs-btn-color);
    background-color: var(--bs-btn-bg);
    border-color: var(--bs-btn-bg);
    border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
}

.filter-group .dropdown-menu.show {
    top: 100%;
    width: 100%;
    left: 0;
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
}
.filter-group .dropdown-menu.show {
    background-color: #2D2534;
    color: #ffffff;
    border: 1px #ffffff solid;
}