.page-profile-coupons{

}
.page-profile-coupons .no-found{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    height: 80vh;
}
.page-profile-coupons .no-found .up{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
}
.page-profile-coupons .no-found .up .text{
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.006rem;
    color: #FFFFFF;
}
.page-profile-coupons .no-found .up .image{
    padding-right: 0.8rem;
}
.page-profile-coupons .no-found .down{
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 17px;
    color: #EED033;
}