.OpenShorts {
    position: absolute;
    z-index: 999999999;
    /* background-color: #24192c; */
    height: 30vh;
    color: #fff;
    width: 100%;
    border-bottom-left-radius: 11px;
    border-bottom-right-radius: 11px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    flex: 1;
}
.OpenShorts .data {
    background-color: #24192c;
    width: 80%;
    border-radius: 11px;
    flex: 1;
}