.bg-topmenu-container {
    color: #FFFFFF;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.1px;
    padding: 1rem 0rem;
    display: flex;
    flex-direction: row !important;
    flex-wrap: nowrap !important;
    align-content: center !important;
    justify-content: center !important;
    align-items: center !important;
    height: 3rem;
}
.offcanvas {
    z-index: 999999;
    background: #2D2534;
    color:#FFFFFF;
}
.offcanvas {
    width: 75% !important;
}
.bg-topmenu-container button.ms-1.me-1.btn.btn-secondary {
    word-wrap: normal;
    white-space: nowrap;
    border-radius: 4rem;
    background-color: #35384B;
    border-color: #35384B;
    padding: 0.2rem 0.75rem;
}
.bg-header-Topmenu{
    background-color: #24192c;
}
.offcanvas-header{
    background-color: #211928;
}
.offcanvas-header .offcanvas-title{
    width:100%;
    text-decoration: underline dotted #F1F1F1;
}
.offcanvas-body{
    word-wrap: break-word;
}
.offcanvas-header .offcanvas-title .selectCityOpen{
    transform: rotateX(180deg);
    animation: selectCityOpenFa 0.5s forwards;
}

@keyframes selectCityOpenFa {
    0% {
        transform: rotateX(0deg);
    }
    100% {
        transform: rotateX(180deg);
    }
}
.offcanvas-header .offcanvas-title .selectCityClose{
    transform: rotateX(0deg);
    animation: selectCityCloseFa 0.5s forwards;
}

@keyframes selectCityCloseFa {
    0% {
        transform: rotateX(180deg);
    }
    100% {
        transform: rotateX(0deg);
    }
}


.offcanvas-body{
    position: relative;
}
.offcanvas-body .citySelectWrap{
    position: absolute;
    top: 0;
    overflow: hidden;
    height: 0;
}
.offcanvas-body .citySelectWrap.open{
    height: 100vh;
    animation: selectCityWrapOpen 0.5s forwards;
}


@keyframes selectCityWrapOpen {
    0% {
        height: 0vh;
    }
    100% {
        height: 100vh;
    }
}

.offcanvas-body .CitySearchInput{
    box-shadow: none !important;
    background-color: #ffffff !important;
    color: #2d2534;
}
.offcanvas .city {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: #EBEBEB;
}

.offcanvas .GoodsLink {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    font-size: 1.4rem;
}
.offcanvas .good {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: #EBEBEB;
}
.offcanvas .complex-link {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    font-size: 1.3em;
}

.image-circle {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    background-color: #f7f9fa;
    border-radius: 50%;
    width: 25px;
    height: 25px;
}