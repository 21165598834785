.GoodMini .card img.card-img-top {
    height: 145px;
    width: max-content;
    position: relative;
}
.GoodMini .card .imgplace {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
.GoodMini .card .card-body {
    background-color: #443453;
}
.GoodMini .card {
    border: none;
}

.GoodMini .card .card-title {
    background-color: #FEEEB5;
    margin: 0;
}
.GoodMini .card .card-title {
    background-color: #FEEEB5;
    margin: 0;
    font-size: 12px;
    text-align: center;
    font-weight: 600;
    padding: 6px 0px;
}
.GoodMini .card .card-text .price {
    width: 100%;
    text-align: center;
    color: #EED033;
    font-weight: bold;
}
.GoodMini .card .name{
    font-size: 12px;
    text-align: center;
    font-weight: 300;
    color: #fff;
    height: 4rem;
}