.card-s-item img {
    width: 100%;
    border-radius: 10px;
    min-height: 190px;
}
.cards-s {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}
.card-s-item.user {
    width: 100%;
    border-radius: 10px;
    min-height: 170px;
    cursor: pointer;
}
.card-s-item.user>.letter {
    color: #FFF;
    font-weight: bold;
    font-size: 6rem;
    text-align: center;
}
.card-s-item.user>.letter2 {
    text-align: center;
    /* font-weight: bold; */
    color: #fff;
    font-size: 1.9em;
    line-height: 0.8em;
    padding-bottom: 1rem;
}
.card-s-item {
    margin-top: -10rem;
    box-shadow: 0px -4px 10px rgb(0 0 0 / 70%);
    border-radius: 10px;
}

.showCard{
    margin-bottom: 10rem;
    animation: cardshow 0.5s forwards;
}
@keyframes cardshow {
    0% {
        margin-bottom: 0rem;
        margin-top: -10rem;
    }
    100% {
        margin-bottom: 10rem;
        margin-top: 0rem;
    }
}

.hideCard{
    margin-bottom: 0rem;
    animation: cardhide 0.5s forwards;
}
@keyframes cardhide {
    0% {
        margin-bottom: 10rem;
        margin-top: 0rem;
    }
    100% {
        margin-bottom: 0rem;
        margin-top: -10rem;
    }
}