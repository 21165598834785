.render {
    position: initial;
    width: 100%;
    left: 0px;
    top: 185px;
    background: #D10C15;
    border-radius: 20px;
    align-items: center;
    flex-direction: column;
    display: flex;
    padding-top: 25px;
}
.render .barcode {
    background: #fff;
    margin: 5%;
    padding: 5%;
    border-radius: 6px;
}
svg#barcode, svg#barcode2 {
    width: 100% !important;
    height: 100%;
}
.render .barcode svg {
    width: 100% !important;
    height: 100%;
}
.render .logo {
    color: #fff;
    width: auto;
    position: initial;
    align-items: center;
    flex-direction: column;
    border-radius: 11px;
}
.logo img {
    width: auto;
    height: 56px;
}
.render .barcode .code {
    text-align: center;
    opacity: 0.7;
    color: #000;
}
.letter {
    color: #FFF;
    font-weight: bold;
    font-size: 4rem;
    text-align: center;
}
.letter2 {
    text-align: center;
    /* font-weight: bold; */
    color: #fff;
    font-size: 0.9em;
    line-height: 0.8em;
    padding-bottom: 1rem;
}