.page-profileMenu .topColumn {
    display: flex;
    align-content: center;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;
}

.page-profileMenu .topColumn img.avatar.rounded-circle {
    flex: 0;
}

.page-profileMenu .topColumn .usernameEmail {
    flex: 1;
}

.page-profileMenu .topColumn .btns {
    display: flex;
    flex: 1 1;
    flex-direction: row;
    justify-content: flex-end;
}

.page-profileMenu .topColumn .btns .btn{
    background-color:unset;
    border: unset;
}

.page-profileMenu .statusname{

}
.page-profileMenu .links p {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.1px;
}