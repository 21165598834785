.heart_fav{

}
.heart_fav{
    position: absolute;
    line-height: 0;
    right: 0.4rem;
    top: 0.4rem;
    z-index: 1;
}
.heart_fav svg path {
    fill: rgb(0 0 0 / 40%);
    transition: fill 0.5s ease;
}
.heart_fav.heart_fav_active svg path {
    fill: rgb(255, 94, 94);
    transition: fill 0.5s ease;
}
.heart_fav svg {
    width: 22px;
    height: 21px;
}
.heart_fav.heart_fav_active svg {
    width: 23px;
    height: 22px;
}