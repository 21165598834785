.shop_badge {
    background-color: #35384B;
    height: 11.5rem;
    border-radius: 11px;
    position: relative;
    padding-top: 1.2rem;
}
.shop_badge .bages .top{
    background: linear-gradient(95.02deg, #AE4F4F 0%, #FA6E6F 101.75%);
    height: 1.4rem;
    width: 5.1rem;
    border-radius: 11px 0px 11px 0px;
    font-size: 0.75rem;
    text-align: center;
    line-height: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0;
    top: 0;
    font-style: normal;
    font-weight: 500;
    z-index: 1;
}
.shop_badge .bages .promo{
    background: linear-gradient(95.02deg, #3F9823 0%, #5AD560 101.75%);
    height: 1.4rem;
    width: 5.1rem;
    border-radius: 11px 0px 11px 0px;
    font-size: 0.75rem;
    text-align: center;
    line-height: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0;
    top: 0;
    font-style: normal;
    font-weight: 500;
    z-index: 1;
}
.shop_badge .bages .action{
    background: linear-gradient(95.02deg, #FF7D39 0%, #FFE16E 101.75%);
    height: 1.4rem;
    width: 5.1rem;
    border-radius: 11px 0px 11px 0px;
    font-size: 0.75rem;
    text-align: center;
    line-height: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0;
    top: 0;
    font-style: normal;
    font-weight: 500;
    z-index: 1;
}

.bages.top-promo .top {
    width: 3.5rem;
}
.bages.top-promo .promo {
    width: 3.5rem;
    left: 3rem;
}

.bages.top-action .top {
    width: 3.5rem;
}
.bages.top-action .action {
    width: 3.5rem;
    left: 3rem;
}

.bages.promo-action .promo {
    width: 3.5rem;
}
.bages.promo-action .action {
    width: 3.5rem;
    left: 3rem;
}

.bages.top-promo-action .action {
    width: 3.6rem;
    left: 4.6rem;
}
.bages.top-promo-action .promo {
    width: 3.5rem;
    left: 1.7rem;
}
.bages.top-promo-action .top {
    width: 2.2rem;
}

.shop_badge .dash {
    width: 100%;
    border-top-width: 0.4px;
    border-top-style: dashed;
    border-top-color: #D7D7D7;
    position: absolute;
    top: 50%;
}
.shop_badge .img_div .img {
    max-width: 100%;
    margin-top: 0.4rem;
    padding: 0.4rem;
    padding-top: 0rem;
    max-height: 2.99rem;
    padding-bottom: 0;
}
.shop_badge .img_div {
    min-height: 2.99rem;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
}
.shop_badge .name {
    font-size: 0.75rem;
    width: 100%;
    display: flex;
    justify-content: center;
    /* line-height: 0.7rem; */
    padding-top: 0.2rem;
    color: #5178E9;
}
.shop_badge .text {
    font-size: 10.5px;
    display: flex;
    justify-content: center;
    padding-top: 20px;
}
.shop_badge .text-cashback {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    padding-top: 0.5rem;
}
.shop_badge .text-cashback .cashback {
    background-color: #FFEFB2;
    color: #000000;
    font-size: 0.8rem;
    line-height: 14px;
    letter-spacing: 0.0701931px;
    font-style: normal;
    font-weight: 600;
    width: auto;
    padding: 0px 3px;
}
.shop_badge .stars {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    padding-top: 0.7rem;
}
.shop_badge .stars .star {
    display: inline-flex;
    height: 18px;
    width: 18px;
}
.shop_badge .stars .star svg{
    width: 100%;
    height: 100%;
}
.shop_badge .stars .star-text {
    display: inline-flex;
    height: 10px;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 11px;
    letter-spacing: 0.0701931px;
}
