.UserCouponMini {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: flex-start;
}

.sUserCouponMini .column-1, .sUserCouponMini .column-2, .sUserCouponMini .column-3 {
    flex: 1;
}
.UserCouponMini .coupon-image img {
    width: 100%;
    border-radius: 0.5rem;
}
.UserCouponMini .coupon-image .expired-before .text {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 0.4rem;
    line-height: 17px;
    color: #000000;
}
.UserCouponMini .coupon-image {
    position: relative;
}
.UserCouponMini .coupon-image .expired-before {
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    background-color: #F7971D;
    border-radius: 0rem 0rem 0.5rem 0.5rem;
}
.UserCouponMini .kod-text,.UserCouponMini .kod {
    text-align: center;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 0.65rem;
}
.UserCouponMini .kod {
    font-weight: 400;
}
.UserCouponMini .coupon-name {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
}
.UserCouponMini .colm {
    min-height: 8rem;
}
.UserCouponMini .colm .icons {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: space-between;
    justify-content: space-around;
    align-items: flex-end;
    height: 100%;
}