.GoodPage {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 999999;
    background: #24192c;
    top: 0;
    right: -100%;
    overflow: auto;
    padding: 0;
}

.GoodPageOpen {
    right: 0%;
    animation: GoodPageOpenAnimation 0.3s forwards;
}

@keyframes GoodPageOpenAnimation {
    0% {
        right: -100%;
    }
    100% {
        right: 0%;
    }
}

.GoodPageClose {
    right: -100%;
    animation: GoodPageCloseAnimation 0.3s forwards;
}

@keyframes GoodPageCloseAnimation {
    0% {
        right: 0%;
    }
    100% {
        right: -100%;
    }
}

.GoodPage .back {
    font-size: 24px;
    display: inline-block;
    padding: 0.5rem 0.5rem;
    width: 100%;
    color: #FFFFFF;
}

.GoodPage .back svg.svg-inline--fa.fa-chevron-left {
    padding-left: 1rem;
}

.ExternalPage .title {
    display: inline-block;
    width: 80%;
    text-align: center;
}