.page-UserCashbackOnline .toplink{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
}
.page-UserCashbackOnline .toplink div{
    display: inline-block;
    font-size: 14px;
}
.page-UserCashbackOnline .toplink div.active {
    border-bottom: 0.12rem #EED033 solid;
}

.page-UserCashbackOnline .tab1,.page-UserCashbackOnline .tab2 {
    display: none;
}










.page-UserCashbackOnline .tab-1 {
    display: flex;
}
.page-UserCashbackOnline .tab-2 {
    display: none;
}

.page-UserCashbackOnline .tab-1.active, .page-UserCashbackOnline .tab-2.active{
    display: flex;
}
.page-UserCashbackOnline .tab-2.active{
    transform: translateX(200%);
}
.page-UserCashbackOnline .tab-1.hide {
    right: 0%;
    animation: Tab1Hide 0.4s forwards;
    display: flex;
    position: absolute;
    top: 0;
}
@keyframes Tab1Hide {
    0% {
        transform: translateX(0%);
    }
    100% {
        transform: translateX(-120%);
        display: none;
    }
}
.page-UserCashbackOnline .tab-2.show {
    right: 0%;
    animation: Tab2Show 0.4s forwards;
}
.page-UserCashbackOnline .tab-1.show {
    right: 0%;
    animation: Tab1Show 0.4s forwards;
    display: flex;
}
@keyframes Tab1Show {
    0% {
        transform: translateX(-120%);
    }
    100% {
        transform: translateX(0%);
    }
}
.page-UserCashbackOnline .tab-2.hide {
    right: 0%;
    animation: Tab2Hide 0.4s forwards;
    display: flex;
    position: absolute;
    top: 0;
    width: 100%;
}

@keyframes Tab2Hide {
    0% {
        transform: translateX(0%);
    }
    100% {
        transform: translateX(120%);
        display: none;
    }
}
.page-UserCashbackOnline .tab-2.show {
    right: 0%;
    animation: Tab2Show 0.4s forwards;
}

@keyframes Tab2Show {
    0% {
        transform: translateX(120%);
    }
    100% {
        transform: translateX(0%);
    }
}